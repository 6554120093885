import React from 'react'

import Layout from '../components/Layout/'
import SEO from '../components/seo'
import SocialLinks from '../components/SocialLinks'
import { useStaticQuery, graphql } from "gatsby"
import { MainContent  } from '../styles/base'
import { PostPhoto } from '../styles/base'




const AboutPage = () => {
  const { avatarImage } = useStaticQuery (
    graphql`
      query {
        avatarImage: file(relativePath: {eq: "kav.jpg"}) {
          childImageSharp {
            fluid(quality: 100){
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )

  return (<Layout>
    <SEO
      title="About Me"
      description="Saiba um pouco mais sobre o desenvolvedor por trás deste blog."
    />
    <MainContent>
      <h1>About Me</h1>
      <p>
      Greetings! My name is Kavish, born and bred in Sri Lanka, Currently working as a Graduate Mechanical Engineer in Perth, Western Australia. Besides being an math nomad I love storytelling and obsessed with travel, culinary adventures and above all a fanatic of the immortal Renaissance world!
      </p>

      <p>
        I studied Mechanical Engineering at Curtin University and at the Sri Lanka Institute of Information Technology, my primary specialisation was on Thermodynamics but eventually ended up being one in Engineering Design and Heavy Machinery. I have 2+ years of postgraduate experience in Design Engineering; specially electronic and consumer product design and manufacturing, Rapid Prototyping techniques such as 3D printing, which includes printing with traditional plastics like PLA to top-notch printers like <strong>Markforged</strong> which uses Carbon fibre. Also experienced in CNC routing and Laser technologies.
      </p>
      <p>
      I have been involved in the Mining and Construction sectors specially related to the engineering aspects which looks around Heavy Machinery, Machine Control and Guidance solutions, more specifically I’ve experience with the <strong>Hemisphere</strong> range of Machine Guidance Solutions; throughout its installation, calibration and troubleshooting processes. Moreover; I’ve  involved in different aspects around Open Pit Mining, with regards to fleet management and productivity enhancement. Also, I have knowledge and experience around the IoT(Internet of Things) and Communication technologies such as LoRaWAN and Wifi, across developing Smart City Solutions and Asset Management and Monitoring Solutions acrosses various use-cases.
      </p>
      <p>
        <strong>Jack of All Trades but Master of None</strong> might supposedly be the right term, but collectively Engineering is all by itself is what I’ve learnt and to give a drop of this immense ocean of knowledge, including other stuff to spice things up is why, this blog was born!
      </p>
      <PostPhoto fluid={avatarImage.childImageSharp.fluid}></PostPhoto>
      <h2>Contact</h2>

      <p>
        You can find me on these social medias
      </p>
        
      <SocialLinks hideStyle />
    </MainContent>
  </Layout>)
}

export default AboutPage
